<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.logsSteps }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-tabs v-model="tab">
        <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab :key="0" data-cy="tab-all-logs">
          {{ $lang.labels.logs }}
        </v-tab>
        <v-tab :key="1" data-cy="tab-failed-logs">
          {{ $lang.labels.failures }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="fill-height">
        <v-tab-item :key="0" class="fill-height">
          <v-row dense no-gutters>
            <v-col cols="12">
              <Table
                :table-key="tab"
                :items="logs"
                :loading="loading"
                @fetchLogs="fetchLogs($event)"
              ></Table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="1" class="fill-height">
          <v-row dense no-gutters>
            <v-col cols="12">
              <failure-logs
                :table-key="tab"
                :items="failures"
                :loading="loadingFailures"
                @fetchLogs="fetchFailures($event)"
              ></failure-logs>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Table from './LogsStepDatatable'
import FailureLogs from './FailureLogsDatatable'
import {
  getProcessStepLogsUsingGET as getLogs,
  getProcessStepFailuresUsingGET as getFailures
} from '@/utils/api'

export default {
  components: {
    Table,
    FailureLogs
  },
  data() {
    return {
      err: '',
      success: '',
      tab: 0,
      loading: false,
      loadingFailures: false,
      logs: { items: [], meta: {} },
      failures: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    fetchFailures(options) {
      this.loadingFailures = true
      getFailures({ dateFrom: options.dateFrom, dateTill: options.dateTill })
        .then((res) => {
          this.failures = res.data.data
          this.loadingFailures = false
        })
        .catch((err) => {
          this.err = err.response?.data?.message || 'Something went wrong'
          this.loadingFailures = false
        })
    },
    fetchLogs(options) {
      this.err = ''
      if (options) {
        if (options.dateFrom > options.dateTill) {
          this.err = this.$lang.errors.dateWrong
          setTimeout(() => this.err = '', 5000)

          return
        }
      }

      const obj = {
        dateFrom: options ? options.dateFrom : '',
        dateTill: options ? options.dateTill : ''
      }

      if (options) {
        if (this.tab === 0 && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page
          obj.size = options.options.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.processId) {
        obj.processId = options.processId
        if ([null, undefined, 'null', 'undefined', '', 0].includes(obj.processId)) delete obj.processId
      }

      if (options && options.eventId) {
        obj.eventId = options.eventId
      }

      if (options && options.stepId) {
        obj.stepId = options.stepId
      }

      if (options && options.stepType) {
        obj.stepType = options.stepType
      }

      if (options && options.statusType) {
        obj.status = options.statusType
      }

      this.loading = true
      getLogs(obj)
        .then((res) => {
          this.logs = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    }
  }
}
</script>
