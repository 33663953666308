var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"2"}},[_c('v-datetime-picker',{attrs:{"label":_vm.$lang.labels.from,"no-title":"","text-field-props":{ outlined: true, dense: true }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-calendar")])]}},{key:"timeIcon",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-clock-outline")])]}}]),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"pl-2",attrs:{"cols":"2"}},[_c('v-datetime-picker',{attrs:{"label":_vm.$lang.labels.to,"no-title":"","text-field-props":{ outlined: true, dense: true }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-calendar")])]}},{key:"timeIcon",fn:function(ref){return [_c('v-icon',[_vm._v("mdi-clock-outline")])]}}]),model:{value:(_vm.dateTill),callback:function ($$v) {_vm.dateTill=$$v},expression:"dateTill"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.sendQuery()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(index + 1))])]}},{key:"item.processId",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.goToProcess(item)}}},[_vm._v(_vm._s(item.processId))])],1)]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.count))])]}},{key:"item.counter",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.counter))])]}},{key:"item.exception",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-primary clickable-simple",staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"300px","text-overflow":"ellipsis"},on:{"click":function($event){!item.exception ? '' : _vm.setSelectedEst(item)}}},[_vm._v(" "+_vm._s(item.exception)+" ")])]}},{key:"item.firstOccurrence",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"300px","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm._f("formatDateTimePrecise")(item.firstOccurrence))+" ")])]}},{key:"item.lastOccurrence",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","max-width":"300px","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm._f("formatDateTimePrecise")(item.lastOccurrence))+" ")])]}}])}),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"content-class":"text-center","top":""},model:{value:(_vm.snackShow),callback:function ($$v) {_vm.snackShow=$$v},expression:"snackShow"}},[_c('span',{staticClass:"color-accent-text"},[_vm._v(_vm._s(_vm.snackbarText))])]),(_vm.showSelectedEst)?_c('v-dialog',{staticStyle:{"min-height":"80vh","max-height":"80vh"},attrs:{"max-width":"71%"},model:{value:(_vm.showSelectedEst),callback:function ($$v) {_vm.showSelectedEst=$$v},expression:"showSelectedEst"}},[_c('log-est',{attrs:{"data":_vm.selectedEst},on:{"closeDialog":function($event){_vm.selectedEst = null; _vm.showSelectedEst = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }